<template>
  <div>
    <AsupBreacrump
      v-if="asup && !isLoading"
      :asup-id="asup.id"
      :asset-id="assetId"
      :system-type="1009"
      :enable-no-asset-alert="false"
      @trigger-delta-build="triggerBuild(false)"
      @trigger-full-build="triggerBuild(true)"
    />

    <b-overlay
      no-center
      :show="hideDetails && isLoading === false"
      z-index="9999"
      opacity="0.95"
    >
      <template #overlay>
        <div class="text-center mt-5">
          <h3>Contract expired.</h3>
          <p>Please contact the mentIQ sales team to get more information.</p>
          <b-button
            ref="cancel"
            variant="outline-secondary"
            size="sm"
            @click="$router.go(-1)"
          >
            Back
          </b-button>
        </div>
      </template>

      <div
        ref="stickyNav"
        class="sticky-nav"
      >
        <b-card no-body>
          <b-card-header
            header-tag="nav"
            header-class="asup-detail-nav"
          >
            <p
              v-if="asup"
              class="text-muted asupShortIdentifier"
            >
              {{ asup.hostname }} / {{ asup.version }}
            </p>
            <b-nav
              card-header
              tabs
            >
              <b-nav-item
                :to="{ name: 'agent-detail-v2.general' }"
                exact-active-class="active"
              >
                General
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'agent-detail-v2.clients' }"
                exact-active-class="active"
              >
                Clients
              </b-nav-item>

              <b-nav-item
                v-if="$can('AsupManagement.RawAsupSystem')"
                :to="{ name: 'agent-detail-v2.raw' }"
                exact-active-class="active"
              >
                Raw data
              </b-nav-item>
            </b-nav>
          </b-card-header>
        </b-card>
      </div>

      <router-view
        v-if="!isLoading"
        :asup="asup"
        :asset="asset"
      />
    </b-overlay>

    <b-overlay
      :show="isLoading"
      no-wrap
      fixed
      z-index="999"
    />
  </div>
</template>

<script>
import {
  BNav,
  BNavItem,
  BOverlay,
  BCard,
  BCardHeader,
  BButton,
} from 'bootstrap-vue'
import AsupDetailMixin from '@/mixins/AsupDetailMixin'

import AgentService from '@/service/agent-v2.service'
import AsupBreacrump from '@/views/asup/AsupBreadcrump.vue'

export default {
  components: {
    BNav,
    BNavItem,
    BOverlay,
    BCard,
    BCardHeader,
    BButton,
    AsupBreacrump,
  },
  mixins: [AsupDetailMixin],
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      AgentService.getAsync(this.$route.params.id, {
        disableTenantFilter: true,
      }).then(result => {
        this.asup = result
        this.loadAsset()
      })
    },
    triggerBuild(_force) {
      const force = _force || false
      AgentService.triggerBuildAsync(this.$route.params.id, { force }).then(
        () => {
          this.$toast.success(
            'Build triggered. This can take a while. Please refresh the page after a few seconds.',
            {
              icon: true,
            },
          )
        },
      )
    },
  },
}
</script>

<style></style>
