<template>
  <div>
    <b-modal
      v-model="isShow"
      title="Execution details"
      size="xl"
    >
      <b-row>
        <b-col>
          <h4>Context</h4>

          <dl class="row">
            <dt class="col-sm-3">
              Context Id
            </dt>
            <dd class="col-sm-9">
              {{ monitorContext.contextId }}
            </dd>

            <dt class="col-sm-3">
              Started on
            </dt>
            <dd class="col-sm-9">
              {{ monitorContext.startedOn | formatDateTime }}
            </dd>

            <dt class="col-sm-3">
              Exec. Side
            </dt>
            <dd class="col-sm-9">
              {{ monitorContext.tenantId ? 'Tenant' : 'Host' }}
            </dd>

            <dt class="col-sm-3 text-truncate">
              Duration
            </dt>
            <dd class="col-sm-9">
              {{ monitorContext.duration }}
            </dd>

          </dl>

          <div>
            <h4>Content</h4>
          </div>
          <json-viewer
            :value="contextJson"
            :expand-depth="1"
            sort
          />
          <b-button
            size="sm"
            @click="downloadContext"
          >
            Download
          </b-button>
        </b-col>
        <b-col>
          <h4>Included monitors</h4>
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Exec. duration</th>
                  <th>Result Status</th>
                  <th>Has Exceptions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="monitorInfo in monitorInfos"
                  :key="monitorInfo.monitor.id"
                >
                  <td>{{ (monitorInfo.monitor.checkScript || {}).displayName }}</td>
                  <td>{{ monitorInfo.contextResult.output.scriptExecutionDuration }}</td>
                  <td>{{ monitorInfo.contextResult.output.result.status }}</td>
                  <td>{{ monitorInfo.contextResult.output.result.successfullExecution === false ? 'Yes' : 'No' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import {
  BRow, BCol, BModal, BButton,
} from 'bootstrap-vue'
import JsonViewer from 'vue-json-viewer'
import ScriptExecutionContextProfile from '@/service/scriptExecutionContextProfile.service'
import MonitorService from '@/service/monitor.service'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    JsonViewer,
  },
  data() {
    return {
      isShow: false,
      monitorContext: {},
      monitorInfos: [],
    }
  },
  computed: {
    contextJson() {
      if (this.monitorContext.context) {
        return this.monitorContext.context
      }

      return {}
    },
  },
  methods: {
    show(monitorContextId) {
      this.isShow = true
      this.resetModal()

      ScriptExecutionContextProfile.getAsync(monitorContextId)
        .then(result => {
          this.monitorContext = result

          for (let i = 0; i < this.monitorContext.context.scripts.length; i += 1) {
            const scriptInfo = this.monitorContext.context.scripts[i]
            const { monitorId } = scriptInfo

            MonitorService.getAsync(monitorId, { disableTenantFilter: true })
              .then(monitorResult => {
                this.monitorInfos.push({
                  monitor: monitorResult.data,
                  contextResult: scriptInfo,
                })
              })
          }
        })
    },
    downloadContext() {
      ScriptExecutionContextProfile.downloadContextAsync(this.monitorContext.contextId, { disableTenantFilter: true })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          const url = window.URL.createObjectURL(new Blob([result.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.monitorContext.contextId}.json`)
          document.body.appendChild(link)
          link.click()
          link.remove()// you need to remove that elelment which is created before.
        })
    },
    resetModal() {
      this.monitorContext = {}
      this.monitorInfos = []
    },
  },
}

</script>
