<template>
  <div>
    <b-list-group-item
      class="flex-column align-items-start"
      :class="{'bg-light': !hasSystemSpecificMonitor }"
    >
      <div class="d-flex w-100 justify-content-between">
        <div>
          <h5 class="mb-0 text-primary">
            <b-form-checkbox
              v-model="(validMonitor || {}).enabled"
              switch
              :disabled="!hasSystemSpecificMonitor && !$can('Watchdog.Monitors.OverwriteSystems')"
              @change="onClickCheckScript"
            >
              <small>({{ (validMonitor || {}).sort || 0 }})</small>
              {{ checkScript.displayName }}
            </b-form-checkbox>
          </h5>
          <span
            v-if="(validMonitor || {}).notes"
            class="text-muted ml-4"
          >{{ (validMonitor || {}).notes }}
          </span>
        </div>

        <div style="width: 300px; text-align: right">
          <b-badge
            v-if="hasSystemSpecificMonitor"
            v-b-tooltip.hover
            class="mr-1"
            pill
            variant="primary"
            title="Global monitor is overwritten."
          >
            Overwritten
          </b-badge>
          <b-badge
            v-if="!hasSystemSpecificMonitor"
            v-b-tooltip.hover
            variant="secondary"
            class="mr-1"
            pill
            title="Global monitor is applied to this system."
          >
            Global
          </b-badge>
          <b-badge
            v-if="checkScript.isManaged === true"
            v-b-tooltip.hover
            variant="info"
            class="mr-1"
            pill
            title="Monitor script is managed by mentIQ."
          >
            Managed
          </b-badge>
          <b-dropdown size="sm">
            <template #button-content>
              <feather-icon icon="SettingsIcon" />
            </template>
            <b-dropdown-item
              v-if="hasSystemSpecificMonitor"
              @click="onClickEdit"
            >
              Settings...
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!hasSystemSpecificMonitor && $can('Watchdog.Monitors.OverwriteSystems')"
              @click="overwriteGlobaMonitor"
            >
              Overwrite global monitor
            </b-dropdown-item>
            <b-dropdown-item
              v-if="hasSystemSpecificMonitor && $can('Watchdog.Monitors.OverwriteSystems')"
              @click="resetToGlobalMonitor"
            >
              Reset to global monitor
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div v-show="showDetails">

        <div class="ml-5">

          <b-form-checkbox-group class="mt-1">
            <b-form-checkbox
              size="sm"
              :checked="(validMonitor || {}).enableWarningAlert"
              disabled
            >
              Warning alert
            </b-form-checkbox>

            <b-form-checkbox
              :checked="(validMonitor || {}).enableErrorAlert"
              size="sm"
              disabled
            >
              Error alert
            </b-form-checkbox>
          </b-form-checkbox-group>

          <div v-if="checkScript.parameters.length > 0">
            <hr>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Value</th>
                  <th>Default value</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="parameter in checkScript.parameters"
                  :key="parameter.name"
                >
                  <td>
                    {{ parameter.name }}
                    <feather-icon
                      v-if="parameter.description"
                      v-b-popover.hover.bottom="parameter.description"

                      icon="HelpCircleIcon"
                      title="Description"
                    />

                    <span
                      v-if="parameter.isRequired"
                      class="muted"
                    >
                      <br> <small class="text-muted">(required)</small>
                    </span>
                  </td>
                  <td>
                    {{ getMonitorArgumentByParameterName(parameter.name) || '-' }}
                  </td>
                  <td>{{ parameter.defaultValue }}</td>
                </tr>
              </tbody>
            </table>
            <hr>
          </div>
        </div>
      </div>
    </b-list-group-item>
  </div>
</template>

<script>
import {
  BListGroupItem, BFormCheckbox, BBadge, VBPopover, BFormCheckboxGroup, VBTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BListGroupItem, BFormCheckbox, BBadge, BFormCheckboxGroup, BDropdown, BDropdownItem,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: {
    checkScript: {
      type: Object,
      required: true,
      default: () => {},
    },
    monitor: {
      type: Object,
      required: false,
      default: () => {},
    },
    systemMonitor: {
      type: Object,
      required: false,
      default: () => {},
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    hasSystemSpecificMonitor() {
      return !!(this.systemMonitor || {}).id
    },
    validMonitor() {
      return this.hasSystemSpecificMonitor ? this.systemMonitor : this.monitor
    },
  },
  methods: {
    onClickCheckScript(val) {
      this.$emit('changeEnabled', {
        enabled: val,
        checkScript: this.checkScript,
        monitor: this.validMonitor,
      })
    },
    onClickEdit() {
      this.$emit('clickEdit', {
        checkScript: this.checkScript,
        monitor: this.validMonitor,
      })
    },
    getMonitorArgumentByParameterName(parameterName) {
      if (this.monitor) {
        return this.validMonitor.scriptArguments[parameterName]
      }

      return null
    },
    overwriteGlobaMonitor() {
      this.$emit('overwriteGlobalMonitor', {
        checkScript: this.checkScript,
        monitor: this.monitor,
      })
    },
    resetToGlobalMonitor() {
      this.$emit('resetToGlobalMonitor', {
        monitor: this.systemMonitor,
      })
    },
  },
}

</script>
