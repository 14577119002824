import { extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'

import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  max as rule_max,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
} from 'vee-validate/dist/rules'

// eslint-disable-next-line object-curly-newline
import { validatorPositive, validatorUrlValidator } from './validators'

// Install English
localize({
  en,
})

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const max = extend('max', rule_max)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

/*
Map server errors in Http response to a list of errors which can be used by vee-validate setErrors() method.
*/
export const mapServerFieldValidationErrors = (_httpResponse, _fieldMapping) => {
  const result = {} // Record<string, string | string[]> // see https://vee-validate.logaretm.com/v3/api/validation-observer.html#methods
  const fieldMapping = _fieldMapping || {} // map serverSide fields to client side fields

  if (!_httpResponse?.data?.error?.validationErrors) {
    return result
  }

  for (let i = 0; i < _httpResponse.data.error.validationErrors.length; i += 1) {
    const serverValidationError = _httpResponse.data.error.validationErrors[i]
    for (let j = 0; j < serverValidationError.members.length; j += 1) {
      const serverSideFieldName = serverValidationError.members[j]
      const clientSideFieldName = fieldMapping[serverSideFieldName] || serverSideFieldName

      result[clientSideFieldName] = result[clientSideFieldName] || []
      result[clientSideFieldName].push(serverValidationError.message)
    }
  }

  return result
}
