<template>
  <div>
    <b-modal
      v-model="isShow"
      title="Last 50 Monitor executions"
      size="xl"
    >
      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th colspan="4" />
              <th colspan="2">
                Included scripts
              </th>
            </tr>
            <tr>
              <th>Context Id</th>
              <th>Tenant Id</th>
              <th>Started on</th>
              <th>Duration (ms)</th>
              <th>Count</th>
              <th>With exceptions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="context in scriptExecutionContexts"
              :key="context.id"
            >
              <td>
                <b-link
                  @click="$refs.asupMonitorContextDetailModal.show(context.contextId)"
                >
                  {{ context.contextId }}
                </b-link></td>
              <td>{{ context.tenantId }}</td>
              <td>{{ context.startedOn | formatDateTime }}</td>
              <td>{{ getDurationMilliseconds(context.duration) }}</td>
              <td>{{ context.scriptCount }}</td>
              <td>{{ context.scriptExceptionCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>

    <AsupMonitorContextDetailModal ref="asupMonitorContextDetailModal" />
  </div>
</template>

<script>

import { BModal, BLink } from 'bootstrap-vue'
import AsupMonitorContextDetailModal from './AsupMonitorContextDetailModal.vue'
import ScriptExecutionContextProfile from '@/service/scriptExecutionContextProfile.service'

export default {
  components: {
    BModal,
    BLink,
    AsupMonitorContextDetailModal,
  },
  data() {
    return {
      isShow: false,
      asupId: null,
      scriptExecutionContexts: [],
    }
  },
  methods: {
    show(asupId) {
      this.asupId = asupId
      this.isShow = true

      ScriptExecutionContextProfile.getListAsync({ systemId: asupId, maxResultCount: 50 })
        .then(result => {
          this.scriptExecutionContexts = result.items
        })
    },
    getDurationMilliseconds(duration) {
      return this.$moment.duration(duration).asMilliseconds()
    },
  },
}

</script>
