// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {
  async getAllListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 50
    }

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get('/autosupport/agent-v2', requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAsync(id, config) {
    const result = await axios.get(`/autosupport/agent-v2/${id}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async getByClientSystemId(clientSystemId, config) {
    const result = await axios.get(`/autosupport/agent-v2/by-client/${clientSystemId}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async triggerBuildAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post(`/autosupport/agent-v2/${id}/build`, {}, requestConfig)
    return result.data
  },

  async triggerBuildAllAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post('/autosupport/agent-v2/build', {}, requestConfig)
    return result.data
  },
}
