<template>
  <div>
    <b-modal
      v-model="isShow"
      title="Monitor settings"
      size="xl"
    >
      <system-monitor
        :system-id="systemId"
        :system-type="systemType"
      />
    </b-modal>
  </div>
</template>

<script>

import { BModal } from 'bootstrap-vue'
import systemMonitor from '@/views/monitor/systemMonitor.vue'

export default {
  components: {
    BModal,
    systemMonitor,
  },
  props: {
    systemId: {
      type: String,
      required: true,
    },
    systemType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isShow: false,
      asupId: null,
    }
  },
  methods: {
    show(asupId) {
      this.asupId = asupId
      this.isShow = true
    },
  },
}

</script>
