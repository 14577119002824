import { mapGetters } from 'vuex'
import '@/assets/scss/asup/asup.scss'

import AssetService from '@/service/asset.service'
import AgentService from '@/service/agent.service'

export default {
  data() {
    return {
      asup: null,
      asset: null,
      agent: null,
      isLoading: false,
      asupNavIntersectionObserver: null,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
      isSystemAdmin: 'auth/isSystemAdmin',
    }),
    hideDetails() {
      // Hide details if asset is not defined or asset is expired and not in grace period. Host users can always see data
      if (this.isCurrentUserHost) {
        return false
      }

      return !this.asup || (this.asup.status === 2 && this.asup.isInGracePeriod === false)
    },
    assetId() {
      if (this.asset) {
        return this.asset.id
      }

      return null
    },
    agentId() {
      if (this.agent) {
        return this.agent.id
      }

      return null
    },
    agentVersion() {
      if (this.agent) {
        return this.agent.agentVersion
      }

      return null
    },
  },
  mounted() {
    // get the sticky element
    this.asupNavIntersectionObserver = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1 && e.intersectionRatio !== 0) { // at opening the page, the second call in observer has ratio 0, so it would hide the main nav. dont know why, so we just ignore it at ratio 0
          e.target.classList.add('is-sticked')
          // document.body.classList.add('has-active-sticky-subnav')
          document.querySelector('.header-navbar').style.display = 'none'
          document.querySelector('.header-navbar-shadow').style.display = 'none'
        } else {
          e.target.classList.remove('is-sticked')
          // document.body.classList.remove('has-active-sticky-subnav')
          document.querySelector('.header-navbar').style.display = 'block'
          document.querySelector('.header-navbar-shadow').style.display = 'block'
        }
      },
      { threshold: [1] },
    )

    this.asupNavIntersectionObserver.observe(this.$refs.stickyNav)
  },
  beforeDestroy() {
    // document.body.classList.remove('has-active-sticky-subnav')
    this.asupNavIntersectionObserver.unobserve(this.$refs.stickyNav)

    document.querySelector('.header-navbar').style.display = 'block'
    document.querySelector('.header-navbar-shadow').style.display = 'none'
  },
  methods: {
    loadAsset() {
      this.isLoading = true

      const agentPromise = this.asup.agentId
        ? AgentService.getByAgentId(this.asup.agentId, { disableTenantFilter: true }).then(agentResult => {
          this.agent = agentResult
        })
        : Promise.resolve()

      const assetPromise = this.asup.assetId
        ? AssetService.getAsync(this.asup.assetId, { disableTenantFilter: true }).then(
          assetResult => {
            this.asset = assetResult
          },
          () => {
            this.$toast.warning('Failed to load asset data.', {
              icon: true,
            })
          },
        )
        : Promise.resolve()

      Promise.all([agentPromise, assetPromise]).finally(() => {
        this.isLoading = false
      })
    },
  },
}
