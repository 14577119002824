<template>
  <div class="monitor-list">
    <MonitorListItem
      v-for="checkScript in orderedCheckScripts"
      :key="checkScript.id"
      :check-script="checkScript"
      :monitor="getValidMonitorForCheckScript(checkScript)"
      :system-monitor="getValidSystemMonitorForCheckScript(checkScript)"
      :show-details="showDetails"
      @changeEnabled="onChangeEnabled"
      @clickEdit="onClickEdit"
      @overwriteGlobalMonitor="onOverwriteGlobalMonitor"
      @resetToGlobalMonitor="onResetToGlobalMonitor"
    />

    <EditMonitorModal
      ref="editMonitorModal"
      @cancelModal="$emit('editMonitorModalClose', $event)"
      @monitorUpdated="$emit('reloadData')"
      @monitorCreated="$emit('reloadData')"
    />
  </div>
</template>

<script>

import MonitorListItem from './systemMonitorListItem.vue'
import EditMonitorModal from './editMonitorModal.vue'

import MonitorService from '@/service/monitor.service'

export default {
  components: {
    MonitorListItem, EditMonitorModal,
  },
  props: {
    systemId: {
      type: String,
      required: true,
    },
    systemType: {
      type: Number,
      required: false,
      default: null,
    },
    checkScripts: {
      type: Array,
      default: () => [],
    },
    systemTypeMonitors: { // monitors available for system type
      type: Array,
      default: () => [],
    },
    systemMonitors: { // monitors available for specific system
      type: Array,
      default: () => [],
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    orderedCheckScripts() {
      return this.checkScripts
        .concat()
        .sort((a, b) => (a.sort > b.sort ? 1 : -1))
    },
  },
  created() {
    const self = this
    this.checkScripts.forEach(checkScript => {
      const validMonitor = self.getValidMonitorForCheckScript(checkScript)
      const validSystemMonitor = self.getValidSystemMonitorForCheckScript(checkScript)

      if (validSystemMonitor) {
        self.$set(checkScript, 'sort', validSystemMonitor.sort)
      } else if (validMonitor) {
        self.$set(checkScript, 'sort', validMonitor.sort)
      } else {
        self.$set(checkScript, 'sort', 0)
      }
    })
  },
  methods: {
    getValidMonitorForCheckScript(checkScript) {
      // get valid monitors of correct system type
      const monitors = this.systemTypeMonitors.filter(x => x.systemType === checkScript.systemType && x.checkScriptId === checkScript.id)

      // there should exist only 1 monitor!
      if (monitors.length === 1) {
        return monitors[0]
      }
      if (monitors.length > 1) {
        this.$log.error(`Multiple valid monitors found for checkScript ${checkScript.id}. Use ${monitors[0].id}`)
        return monitors[0]
      }

      return null
    },
    getValidSystemMonitorForCheckScript(checkScript) {
      // get valid monitors of correct system type
      const monitors = this.systemMonitors.filter(x => x.systemType === checkScript.systemType && x.checkScriptId === checkScript.id)

      // there should exist only 1 monitor!
      if (monitors.length === 1) {
        return monitors[0]
      }
      if (monitors.length > 1) {
        this.$log.error(`Multiple valid monitors found for checkScript ${checkScript.id}. Use ${monitors[0].id}`)
        return monitors[0]
      }

      return null
    },
    onChangeEnabled(event) {
      if (event.enabled === true) {
        this.$refs.editMonitorModal.show(event.checkScript.id, (event.monitor || {}).id, this.systemId)
      } else if (event.monitor !== null) {
        // disable monitor
        MonitorService.disableAsync(event.monitor.id)
          .then(() => {
            this.$toast.success('Monitor disabled', {
              icon: true,
            })
          })
          .catch(e => {
            if (e.response.data.error.code === '1000') {
              this.$swal({
                title: 'Something went wrong!',
                text: 'Please click on the gear icon on the right side of the line and click "Overwrite global monitor". After that you can deactivate the monitor using the green switch button.',
                icon: 'error',
              })
            } else {
              this.$swal({
                title: 'Something went wrong!',
                text: e.response.data.error.message,
                icon: 'error',
              })
            }
          })
      }
    },
    onClickEdit(event) {
      this.$refs.editMonitorModal.show(event.checkScript.id, (event.monitor || {}).id, this.systemId)
    },
    onOverwriteGlobalMonitor(event) {
      if (event.monitor === null) {
        this.$refs.editMonitorModal.show(event.checkScript.id, null, this.systemId)
      } else {
        MonitorService.overwriteAsync(event.monitor.id, { systemId: this.systemId })
          .then(overwriteMonitorResult => {
            this.$emit('reloadData')
            this.$refs.editMonitorModal.show(event.checkScript.id, overwriteMonitorResult.data.id, this.systemId)
          })
      }
    },
    onResetToGlobalMonitor(event) {
      MonitorService.deleteAsync(event.monitor.id)
        .then(() => {
          this.$emit('reloadData')
        })
    },
  },
}

</script>
