// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {
  async getAllListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params || {}
    requestConfig.paramsSerializer = parseGetParams

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 50
    }

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get('/watchdog/checkscript', requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAsync(id, config) {
    const response = await axios.get(`/watchdog/checkscript/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async getScriptAsync(id, config) {
    const response = await axios.get(`/watchdog/checkscript/${id}/script`, buildAxiosRequestConfig(config))
    return response
  },

  async updateAsync(id, data, config) {
    const response = await axios.put(`/watchdog/checkscript/${id}`, data, buildAxiosRequestConfig(config))
    return response
  },

  async createAsync(data, config) {
    const response = await axios.post('/watchdog/checkscript', data, buildAxiosRequestConfig(config))
    return response
  },

  async deleteAsync(id, config) {
    const response = await axios.delete(`/watchdog/checkscript/${id}`, buildAxiosRequestConfig(config))
    return response
  },
}
