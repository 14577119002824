// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {
  async getListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    const result = await axios.get('/watchdog/context', requestConfig)
    return result.data
  },

  async getAsync(contextId, config) {
    const result = await axios.get(`/watchdog/context/${contextId}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async downloadContextAsync(contextId, config) {
    const result = await axios.get(
      `/watchdog/context/${contextId}/downloadContext`,
      buildAxiosRequestConfig(
        config,
        requestConfig => {
          // eslint-disable-next-line no-param-reassign
          requestConfig.responseType = 'arraybuffer'
        },
      ),
    )

    return result
  },

}
