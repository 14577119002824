<template>
  <div>
    <b-row>
      <b-col cols="12">

        <b-card>
          <b-form inline>
            <b-form-group>
              <b-form-checkbox
                v-model="showDetails"
              >
                Show details
              </b-form-checkbox>
            </b-form-group></b-form>

        </b-card>

        <div class="nav-wrapper">
          <b-card no-body>
            <b-tabs
              pills
              card
              vertical
            >
              <b-tab
                v-for="st in availableSystemTypes"
                :key="st"
                :title="getMonitorSystemTypeDisplayName(st)"
                :active="selectedSystemType === st"
                lazy
                @click="selectedSystemType = st"
              >
                <b-card-text>
                  <b-row>
                    <b-col>
                      <systemMonitorList
                        :system-id="systemId"
                        :system-type="st"
                        :check-scripts="filteredCheckScripts"
                        :system-type-monitors="systemTypeMonitors"
                        :system-monitors="systemMonitors"
                        :show-details="showDetails"
                        @editMonitorModalClose="onEditMonitorModalClose"
                        @reloadData="onReloadData"
                      />

                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow, BCard, BCardText, BTabs, BTab, BForm, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'

import systemMonitorList from './systemMonitorList.vue'

import MonitorService from '@/service/monitor.service'
import CheckScriptService from '@/service/checkScript.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormCheckbox,
    systemMonitorList,
  },
  props: {
    systemId: {
      type: String,
      required: true,
    },
    systemType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedSystemType: null,
      checkScripts: [],
      monitors: [],
      showDetails: false,
    }
  },
  computed: {
    filteredCheckScripts() {
      return this.checkScripts.filter(x => x.systemType === this.selectedSystemType)
    },
    availableSystemTypes() {
      const systemTypes = [null, this.systemType]

      // sort
      return systemTypes.sort((a, b) => a - b)
    },
    filteredMonitors() {
      return this.monitors.filter(x => x.systemType === this.selectedSystemType)
    },
    systemTypeMonitors() {
      return this.filteredMonitors.filter(x => x.systemId === null)
    },
    systemMonitors() {
      return this.filteredMonitors.filter(x => x.systemId === this.systemId)
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.isLoading = true

      this.checkScripts = await CheckScriptService.getAllListAsync()

      this.monitors = await MonitorService.getAllListAsync({ systemIds: [this.systemId], includeNullSystemId: true })

      this.isLoading = false
    },
    getMonitorSystemTypeDisplayName(systemType) {
      if (systemType === null) {
        return 'Global'
      }

      return this.$options.filters.systemTypeDisplayName(systemType)
    },
    onEditMonitorModalClose() {
      this.loadData()
    },
    onReloadData() {
      this.loadData()
    },
  },
}
</script>

<style scoped>
  .nav-wrapper >>> ul.nav {
    justify-content: flex-start!important;
  }
</style>
