<template>
  <div>
    <b-form>
      <b-modal
        v-model="isShow"
        dialog-class="wizard-modal"
        size="lg"
        title="Create Monitor Template"
        no-close-on-backdrop
        scrollable
        hide-footer
        hide-header
      >
        <b-overlay :show="isLoading || isSaving">

          <form-wizard
            :color="variables.primary"
            title="Edit monitor"
            :subtitle="checkScript.displayName"
            step-size="xs"
            shape="square"
            finish-button-text="Submit"
            back-button-text="Previous"
            class="mb-3"
          >

            <tab-content
              title="Parameter"
              :before-change="validationFormScript"
            >
              <validation-observer
                ref="scriptRules"
                tag="form"
              >
                <b-row>
                  <b-col cols="12">
                    <b-form-group>
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>Parameter</th>
                            <th>Value</th>
                            <th>Default value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="parameter in checkScript.parameters"
                            :key="parameter.name"
                          >
                            <td>
                              {{ parameter.name }}
                              <feather-icon
                                v-if="parameter.description"
                                v-b-popover.hover.bottom="parameter.description"

                                icon="HelpCircleIcon"
                                title="Description"
                              />

                              <br>
                              <small
                                v-if="parameter.type === 0"
                                class="text-muted"
                              >
                                Text
                              </small>
                              <small
                                v-if="parameter.type === 1"
                                class="text-muted"
                              >
                                Number
                              </small>
                              <small
                                v-if="parameter.type === 2"
                                class="text-muted"
                              >
                                Yes/No
                              </small>
                            </td>
                            <td>
                              <b-form-group>
                                <b-form-input
                                  v-if="parameter.type === 0 || parameter.type === 1"
                                  v-model="parameter.value"
                                  :type="parameter.type === 0 ? 'text' : 'number'"
                                  size="sm"
                                  :disabled="!hasPermissionToSave"
                                />
                                <b-form-select
                                  v-if="parameter.type === 2"
                                  v-model="parameter.value"
                                  size="sm"
                                  :disabled="!hasPermissionToSave"
                                >
                                  <b-form-select-option
                                    :value="null"
                                  >
                                    - Not set -
                                  </b-form-select-option>
                                  <b-form-select-option :value="'0'">
                                    False
                                  </b-form-select-option>
                                  <b-form-select-option :value="'1'">
                                    True
                                  </b-form-select-option>
                                </b-form-select>

                                <small
                                  v-if="parameter.validationMessage"
                                  class="text-danger"
                                >
                                  {{ parameter.validationMessage }}
                                </small>

                              </b-form-group>
                            </td>
                            <td>{{ parameter.defaultValue }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <small class="text-muted">
                      <b-link @click="showCheckScriptCode = !showCheckScriptCode">
                        {{ showCheckScriptCode ? 'Hide script code' : 'Show script code' }}
                      </b-link>
                    </small>

                    <highlight-code
                      v-if="showCheckScriptCode"
                      lang="javascript"
                    >
                      {{ checkScriptCode }}
                    </highlight-code>
                  </b-col>
                </b-row>

              </validation-observer>
            </tab-content>

            <tab-content
              title="Alert Settings"
              :before-change="validationFormSettings"
            >
              <validation-observer
                ref="settingsRules"
                tag="form"
              >

                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox
                        v-model="form.enableInfoAlert"
                        :disabled="!hasPermissionToSave"
                      >
                        Enable info alerts
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox
                        v-model="form.enableWarningAlert"
                        :disabled="!hasPermissionToSave"
                      >
                        Enable warning alerts
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox
                        v-model="form.enableErrorAlert"
                        :disabled="!hasPermissionToSave"
                      >
                        Enable danger alerts
                      </b-form-checkbox>
                    </b-form-group>

                    <validation-provider
                      v-slot="validationContext"
                      name="shortAlertMessage"
                      :rules="{ required: true, max: 5000 }"
                    >
                      <b-form-group
                        label="Short message:"
                        label-for="input-shortalertmessage"
                      >
                        <b-form-textarea
                          id="input-shortalertmessage"
                          v-model="form.shortAlertMessage"
                          :disabled="!hasPermissionToSave"
                          rows="8"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-shortalertmessage-live-feedback"
                        />
                        <b-form-invalid-feedback id="input-shortalertmessage-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      v-slot="validationContext"
                      name="alertMessage"
                      :rules="{ required: true, max: 5000 }"
                    >
                      <b-form-group
                        label="Alert message:"
                        label-for="input-alertmessage"
                      >
                        <b-form-textarea
                          id="input-alertmessage"
                          v-model="form.alertMessage"
                          :disabled="!hasPermissionToSave"
                          rows="8"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-alertmessage-live-feedback"
                        />
                        <b-form-invalid-feedback id="input-alertmessage-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                  </b-col>
                </b-row>

              </validation-observer>
            </tab-content>

            <tab-content
              title="Misc."
              :before-change="validationNotesSettings"
            >
              <validation-observer
                ref="notesRules"
                tag="form"
              >

                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox
                        v-model="form.enableErrorAlert"
                        :disabled="!hasPermissionToSave"
                      >
                        Enable danger alerts
                      </b-form-checkbox>
                    </b-form-group>

                    <b-form-input
                      v-model="form.sort"
                      type="number"
                      size="sm"
                      :disabled="!hasPermissionToSave"
                    />

                    <validation-provider
                      v-slot="validationContext"
                      name="notes"
                      :rules="{ max: 2000 }"
                    >
                      <b-form-group
                        label="Note:"
                        label-for="input-note"
                      >
                        <b-form-textarea
                          id="input-note"
                          v-model="form.notes"
                          rows="5"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-note-live-feedback"
                          :disabled="!hasPermissionToSave"
                        />
                        <b-form-invalid-feedback id="input-note-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>

                      <b-form-group
                        v-if="isHost && checkScript.isManaged && !systemId"
                        description="If set, this monitor is used for all tenants as default."
                      >
                        <b-form-checkbox
                          v-model="form.isTemplate"
                          :disabled="!hasPermissionToSave"
                        >
                          Is template
                        </b-form-checkbox>
                      </b-form-group>
                    </validation-provider>

                  </b-col>
                </b-row>

              </validation-observer>
            </tab-content>

            <template
              slot="footer"
              slot-scope="props"
            >
              <div class="wizard-footer-left">
                <b-button
                  class="mr-2"
                  variant="secondary"
                  @click="onCancelModal"
                >
                  Cancel
                </b-button>

                <wizard-button
                  v-if="props.activeTabIndex > 0"
                  :style="props.fillButtonStyle"
                  @click.native="props.prevTab()"
                >
                  Previous
                </wizard-button>
              </div>
              <div class="wizard-footer-right">
                <wizard-button
                  v-if="!props.isLastStep"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="props.nextTab()"
                >
                  Next
                </wizard-button>

                <wizard-button
                  v-if="props.isLastStep && hasPermissionToSave"
                  class="wizard-footer-right finish-button"
                  :style="props.fillButtonStyle"
                  @click.native="save"
                >
                  Save
                </wizard-button>
              </div>
            </template>
          </form-wizard>
        </b-overlay>

        <b-alert
          :show="!hasPermissionToSave"
          variant="info"
        >
          You do not have permission to save changes. This is a readonly view.
        </b-alert>
      </b-modal>
    </b-form>
  </div>
</template>

<script>

import {
  BRow, BCol, BModal, BForm, BFormTextarea, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, VBPopover, BFormCheckbox, BOverlay, BLink, BFormSelect, BFormSelectOption, BAlert,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import {
  // eslint-disable-next-line no-unused-vars
  required, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import variables from '@/assets/scss/variables/_variables.scss'

import CheckScriptService from '@/service/checkScript.service'
import MonitorService from '@/service/monitor.service'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    FormWizard,
    TabContent,
    WizardButton,
    BButton,
    BFormCheckbox,
    BOverlay,
    BLink,
    BFormSelect,
    BFormSelectOption,
    BAlert,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      variables,
      isShow: false,
      checkScript: {},
      checkScriptCode: null,
      showCheckScriptCode: false,
      monitor: null,
      systemId: null,
      form: {
        notes: '',
        enableInfoAlert: true,
        enableWarningAlert: true,
        enableErrorAlert: true,
        shortAlertMessage: '',
        alertMessage: '',
        isTemplate: false,
        sort: 0,
      },
      isLoading: false,
      isSaving: false,
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    hasPermissionToSave() {
      if (this.isOverwrittenMonitor) {
        // monitor is overwritten
        return this.$can('Watchdog.Monitors.OverwriteSystems')
      }

      if (this.monitor) {
        // monitor exists, so user needs update permission
        return this.$can('Watchdog.Monitors.Update')
      }

      // monitor does not exists, so user needs create permission
      return this.$can('Watchdog.Monitors.Create')
    },
    isOverwrittenMonitor() {
      return this.systemId != null
    },
  },
  watch: {
    showCheckScriptCode(val) {
      // load checkScript code if not loaded yet
      if (val === true && this.checkScriptCode === null) {
        this.isLoading = true
        CheckScriptService.getScriptAsync(this.checkScript.id)
          .then(scriptResult => {
            this.checkScriptCode = scriptResult.data
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
  },
  methods: {
    async show(checkScriptId, monitorId, systemId) {
      this.resetModal()

      this.isShow = true
      this.isLoading = true

      this.systemId = systemId

      this.checkScript = (await CheckScriptService.getAsync(checkScriptId)).data

      if (monitorId) {
        this.monitor = (await MonitorService.getAsync(monitorId)).data

        if (this.monitor) {
          this.form = {
            notes: this.monitor.notes,
            enableInfoAlert: this.monitor.enableInfoAlert,
            enableWarningAlert: this.monitor.enableWarningAlert,
            enableErrorAlert: this.monitor.enableErrorAlert,
            shortAlertMessage: this.monitor.shortAlertMessage,
            alertMessage: this.monitor.alertMessage,
            isTemplate: this.monitor.isTemplate,
            sort: this.monitor.sort,
          }
        }
      }

      if (this.checkScript.isManaged === true && !monitorId) {
        this.form.isTemplate = true // make isTemplate default at creating monitors for managed checkscripts
      }

      // find parameter values and assign to parameter to prefill form
      for (let i = 0; i < this.checkScript.parameters.length; i += 1) {
        const parameter = this.checkScript.parameters[i]
        const argument = this.getMonitorArgument(parameter)

        parameter.value = argument
      }

      this.isLoading = false
    },
    resetModal() {
      this.checkScript = {}
      this.checkScriptCode = null
      this.showCheckScriptCode = false
      this.monitor = null
      this.systemId = null
      this.form = {
        notes: '',
        enableInfoAlert: true,
        enableWarningAlert: true,
        enableErrorAlert: true,
        shortAlertMessage: '',
        alertMessage: '',
        isTemplate: false,
        sort: 0,
      }
      this.isLoading = false
      this.isSaving = false
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    validateCheckScript() {
      let isValid = true

      // Check CheckScript parameters
      for (let i = 0; i < this.checkScript.parameters.length; i += 1) {
        const parameter = this.checkScript.parameters[i]

        // reset
        this.$set(this.checkScript.parameters[i], 'validationMessage', null)

        // Check regex
        if (parameter.validationRegexPattern && parameter.value) {
          const regex = new RegExp(parameter.validationRegexPattern)
          if (regex.test(parameter.value) === false) {
            this.$set(this.checkScript.parameters[i], 'validationMessage', 'Value is not valid')

            isValid = false
          }
        }
      }

      return isValid
    },
    validationFormScript() {
      return new Promise((resolve, reject) => {
        this.$refs.scriptRules.validate().then(success => {
          if (success) {
            // CheckScript is required
            if (this.validateCheckScript() === false) {
              reject()
            } else {
              resolve(true)
            }
          } else {
            reject()
          }
        })
      })
    },
    validationFormSettings() {
      return new Promise((resolve, reject) => {
        this.$refs.settingsRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationNotesSettings() {
      return new Promise((resolve, reject) => {
        this.$refs.notesRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    save() {
      this.isSaving = true

      const argumentEntries = new Map(this.checkScript.parameters.map(x => [x.name, x.value]))

      const data = {
        isTemplate: this.form.isTemplate,
        enabled: true,
        notes: this.form.notes,
        enableInfoAlert: this.form.enableInfoAlert,
        enableWarningAlert: this.form.enableWarningAlert,
        enableErrorAlert: this.form.enableErrorAlert,
        shortAlertMessage: this.form.shortAlertMessage,
        alertMessage: this.form.alertMessage,
        checkScriptId: this.checkScript.id,
        systemType: this.checkScript.systemType,
        systemId: this.systemId,
        scriptArguments: Object.fromEntries(argumentEntries),
        sort: this.form.sort,
      }

      if (this.isOverwrittenMonitor) {
        data.isTemplate = false // overwritten monitors can not be templats!
      }

      if (this.monitor) {
        // Update
        MonitorService.updateAsync(this.monitor.id, data)
          .then(response => {
            this.$emit('monitorUpdated', {
              monitor: response.data,
            })

            this.$toast.success('Updated', {
              icon: true,
            })

            this.isShow = false
          })
          .catch(e => {
            this.$swal({
              title: 'Something went wrong!',
              text: e.response.data.error.message,
              icon: 'error',
            })
          })
          .finally(() => {
            this.isSaving = false
          })
      } else {
        // Create
        MonitorService.createAsync(data)
          .then(response => {
            this.$emit('monitorCreated', {
              monitor: response.data,
            })

            this.$toast.success('Created', {
              icon: true,
            })

            this.isShow = false
          })
          .catch(e => {
            this.$swal({
              title: 'Something went wrong!',
              text: e.response.data.error.message,
              icon: 'error',
            })
          })
          .finally(() => {
            this.isSaving = false
          })
      }
    },
    getMonitorArgument(checkScriptParameter) {
      if (this.monitor) {
        return this.monitor.scriptArguments[checkScriptParameter.name]
      }

      return null
    },
    onCancelModal() {
      this.$emit('cancelModal', {
        checkScript: this.checkScript,
        monitor: this.monitor,
      })
      this.isShow = false
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@/assets/scss/wizard-modal.scss';
</style>
