// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {
  async getAllListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 50
    }

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get('/autosupport/agent', requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getByAgentId(agentId, config) {
    const result = await axios.get(`/autosupport/agent/by-agentid/${agentId}`, buildAxiosRequestConfig(config))
    return result.data
  },
}
