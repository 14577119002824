<template>
  <div>
    <portal to="breadcrumb-right">
      <b-button
        v-if="assetId"
        variant="primary"
        size="sm"
        class="mr-1"
        :to="{ name: 'asset-detail-page', params: {id: assetId}}"
      >
        <feather-icon icon="ArrowRightIcon" />
        Go to Asset
      </b-button>
      <b-button
        v-if="assetId && $can('Core.Incidents.Create')"
        :to="{ name: 'asset-create-ticket', params: { id: assetId }}"
        variant="primary"
        size="sm"
        class="mr-1"
      >
        <feather-icon icon="LifeBuoyIcon" />
        Create ticket
      </b-button>
      <b-dropdown
        v-if="isSystemAdmin"
        size="sm"
        text="Manage"
        class="mr-1"
        right
      >
        <template #button-content>
          <feather-icon
            icon="RefreshCwIcon"
          />
        </template>
        <b-dropdown-item
          @click="$emit('trigger-delta-build')"
        >
          <feather-icon
            icon="RefreshCwIcon"
          />
          <span class="ml-1 text-middle">
            Build delta
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="$emit('trigger-full-build')"
        >
          <feather-icon
            icon="RefreshCwIcon"
          />
          <span class="ml-1 text-middle">
            Build
          </span>
        </b-dropdown-item>
      </b-dropdown>

      <b-dropdown
        v-if="$can('Watchdog.Monitors') || $can('Watchdog.ScriptExecutionContextProfiles')"
        size="sm"
        text="Manage"
        right
      >
        <template #button-content>
          <feather-icon
            icon="SettingsIcon"
          />
        </template>
        <b-dropdown-item
          v-if="agentId && agentVersion === 'v1' && $can('Autosupport.Agent')"
          :to="{ name: 'agent-detail.general', params: { id: agentId}}"
        >
          <feather-icon
            icon="LinkIcon"
          />
          <span class="ml-1 text-middle">
            ONEInsight Agent
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="agentId && agentVersion === 'v2' && $can('Autosupport.Agent')"
          :to="{ name: 'agent-detail-v2.general', params: { id: agentId}}"
        >
          <feather-icon
            icon="LinkIcon"
          />
          <span class="ml-1 text-middle">
            ONEInsight Agent v2
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('Watchdog.Monitors')"
          @click="$refs.asupMonitorModal.show(asupId)"
        >
          <feather-icon
            icon="LinkIcon"
          />
          <span class="ml-1 text-middle">
            Monitor settings
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('Watchdog.ScriptExecutionContextProfiles')"
          @click="$refs.asupMonitorContextModal.show(asupId)"
        >
          <feather-icon
            icon="LinkIcon"
          />
          <span class="ml-1 text-middle">
            Monitor execution history
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </portal>

    <AsupMonitorModal
      v-if="asupId"
      ref="asupMonitorModal"
      :system-type="systemType"
      :system-id="asupId"
    />

    <AsupMonitorContextModal
      ref="asupMonitorContextModal"
    />

    <b-alert
      v-if="enableNoAssetAlert"
      variant="warning"
      :show="!assetId && isHost"
    >
      <div class="alert-body">
        <span>This system has no related asset.</span>
      </div>
    </b-alert>
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import {
  BDropdown, BDropdownItem, BAlert, BButton,
} from 'bootstrap-vue'

import AsupMonitorModal from '@/views/asup/monitor/AsupMonitorModal.vue'
import AsupMonitorContextModal from '@/views/asup/monitor/AsupMonitorContextModal.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BAlert,
    BButton,
    AsupMonitorModal,
    AsupMonitorContextModal,
  },
  props: {
    asupId: {
      type: String,
      required: true,
    },
    systemType: {
      type: Number,
      required: true,
    },
    assetId: {
      type: String,
      default: () => null,
    },
    enableNoAssetAlert: {
      type: Boolean,
      default: true,
    },
    agentId: {
      type: String,
      default: () => null,
    },
    agentVersion: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters({
      isSystemAdmin: 'auth/isSystemAdmin',
      isHost: 'auth/isHost',
    }),
  },
}

</script>
